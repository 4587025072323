import { useState } from "@hookstate/core";
import { Validation } from "@hookstate/validation";
import Button from "@material-ui/core/Button";
import { CardProps } from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      maxWidth: 350,
    },
    fields: {
      marginTop: theme.spacing(1.5),
      width: "100%",
    },
    formControl: {
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export interface CreateVirtualMachineFields {
  name: string;
  nameStarted: boolean;
  image: string;
  cpus: number;
  ram: number;
}

export interface CreateVirtualMachineProps extends Partial<CardProps> {
  onCreate?: (data: CreateVirtualMachineFields) => void;
  onCancel?: () => void;
}

export const CreateVirtualMachine: React.FC<CreateVirtualMachineProps> = ({
  children,
  onCreate,
  onCancel,
}) => {
  const classes = useStyles();
  const formSubmitted = useState<boolean>(false);
  const formState = useState<CreateVirtualMachineFields>({
    name: "",
    nameStarted: false,
    image: "dsl.ova",
    cpus: 1,
    ram: 1,
  });
  formState.attach(Validation);

  Validation(formState.name).validate(
    (name) => name !== "" && name.replace(/\s+/g, "") !== "",
    "A machine name is required"
  );

  const handleSubmit = () => {
    if (!Validation(formState.name).valid()) {
      formState.nameStarted.set(true);
      return;
    }

    formSubmitted.set(true);

    onCreate && onCreate({ ...formState.value });
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const textError =
    formState.nameStarted.value &&
    !Validation(formState.name).valid() &&
    Validation(formState.name).errors()[0]?.message;

  return (
    <>
      <CardContent>
        <Typography variant={"h4"} component={"h2"} color="textSecondary">
          Create Virtual Machine
        </Typography>
        <TextField
          className={classes.fields}
          id="create-virtual-machine-name"
          label="Virtual Machine Name"
          variant="outlined"
          value={formState.name.value}
          error={Boolean(textError)}
          helperText={textError}
          onChange={(event) =>
            formState.merge({ name: event.target.value, nameStarted: true })
          }
        />
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-type">Image/OS</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={formState.image.value}
            onChange={(event) =>
              formState.image.set(event.target.value as string)
            }
            label="Image"
          >
            <MenuItem value={"ubuntu16.04-template"}>Ubuntu 16.04</MenuItem>
            <MenuItem value={"ubuntu18.04-template"}>Ubuntu 18.04</MenuItem>
            <MenuItem value={"windows-2016-template"}>Windows 2016</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-cpus">CPUs</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={formState.cpus.value}
            onChange={(event) =>
              formState.cpus.set(event.target.value as number)
            }
            label="CPUs"
          >
            <MenuItem value={1}>One (1)</MenuItem>
            <MenuItem value={2}>Two (2)</MenuItem>
            <MenuItem value={3}>Three (3)</MenuItem>
            <MenuItem value={4}>Four (4)</MenuItem>
            <MenuItem value={5}>Five (5)</MenuItem>
            <MenuItem value={6}>Six (6)</MenuItem>
            <MenuItem value={7}>Seven (7)</MenuItem>
            <MenuItem value={8}>Eight (8)</MenuItem>
            <MenuItem value={9}>Nine (9)</MenuItem>
            <MenuItem value={10}>Ten (10)</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-ram">Memory</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={formState.ram.value}
            onChange={(event) =>
              formState.ram.set(event.target.value as number)
            }
            label="Memory"
          >
            <MenuItem value={0.5}>0.5 GB</MenuItem>
            <MenuItem value={1}>1 GB</MenuItem>
            <MenuItem value={1.5}>1.5 GB</MenuItem>
            <MenuItem value={2}>2 GB</MenuItem>
            <MenuItem value={3}>3 GB</MenuItem>
            <MenuItem value={4}>4 GB</MenuItem>
            <MenuItem value={6}>6 GB</MenuItem>
            <MenuItem value={8}>8 GB</MenuItem>
            <MenuItem value={12}>12 GB</MenuItem>
            <MenuItem value={14}>14 GB</MenuItem>
            <MenuItem value={16}>16 GB</MenuItem>
          </Select>
        </FormControl>
        {children}
      </CardContent>
      <CardActions>
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => handleSubmit()}
          disabled={formSubmitted.get()}
        >
          Create
        </Button>
      </CardActions>
    </>
  );
};
