import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { FunctionComponent } from "react";
import { TableSortLabel } from "@material-ui/core";

type Order = "asc" | "desc";

interface Column {
  id: "name" | "namespace" | "status" | "restarts" | "uptime";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  sort: (a: Container, b: Container) => number;
}

const columns: Column[] = [
  {
    id: "name",
    label: "Container Name",
    minWidth: 170,
    sort: (a: Container, b: Container) => a.name.localeCompare(b.name),
  },
  {
    id: "namespace",
    label: "Namespace",
    minWidth: 80,
    align: "right",
    sort: (a: Container, b: Container) =>
      a.namespace.localeCompare(b.namespace),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "right",
    sort: (a: Container, b: Container) => a.status.localeCompare(b.status),
  },
  {
    id: "restarts",
    label: "Restarts",
    minWidth: 80,
    align: "right",
    sort: (a: Container, b: Container) =>
      parseInt(a.restarts) - parseInt(b.restarts),
  },
  {
    id: "uptime",
    label: "Uptime",
    minWidth: 80,
    align: "right",
    sort: (a: Container, b: Container) => a.status.localeCompare(b.status),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

interface Container {
  id: string;
  name: string;
  namespace: string;
  status: string;
  restarts: string;
  uptime: string;
}

// @ts-ignore
export interface ContainerTableProps {
  containerList: Container[];
}

export const ContainerTable: FunctionComponent<ContainerTableProps> = ({
  containerList,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<Column["id"]>("name");
  const sortRoutine = columns.find((c) => c.id === orderBy)?.sort;
  if (!sortRoutine) {
    throw new Error("sortRoutine not found");
  }

  const handleRequestSort = (property: Column["id"]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {containerList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort((a: Container, b: Container) =>
                order === "asc" ? sortRoutine(a, b) : -sortRoutine(a, b)
              )
              .map((container, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column, idx) => {
                    const value = container[column.id];
                    const v =
                      column.format && typeof value === "number"
                        ? column.format(value)
                        : value;
                    return (
                      <TableCell
                        key={`${column.id}-${i}-${idx}`}
                        align={column.align}
                      >
                        {v}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={containerList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ContainerTable;
