import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AddIcon from "@material-ui/icons/Add";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  CreateContainer,
  CreateContainerFields,
} from "../controls/CreateContainer";
import {
  CreateVirtualMachine,
  CreateVirtualMachineFields,
} from "../controls/CreateVirtualMachine";
import VmTable from "../vm-table/VmTable";
import ContainerTable from "./container-table";

export interface VirtualizationProps {
  handleClickOpen: () => void;
  handleClose: () => void;
  open?: boolean;
  handleChange?: (data: CreateVirtualMachineFields) => Promise<any>;
  createContainerOpen: boolean;
  handleCreateContainerOpen: () => void;
  handleCreateContainerClose: () => void;
  handleCreateContainerChange: (data: CreateContainerFields) => Promise<any>;
  getVmList: () => Promise<any>;
  getContainerList: () => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
  })
);

let vmListIntervalId: number;
let containerListIntervalId: number;

export const Virtualization: FunctionComponent<VirtualizationProps> = (
  props
) => {
  const classes = useStyles();

  const [vmList, setVmList] = useState([]);
  useEffect(() => {
    async function loadVms() {
      const theVmList = await props.getVmList();
      setVmList(theVmList);
    }
    loadVms();
    vmListIntervalId = setInterval(async () => {
      await loadVms();
    }, 5000);
    return () => clearInterval(vmListIntervalId);
  }, []);

  const [containerList, setContainerList] = useState([]);
  useEffect(() => {
    async function loadContainers() {
      const theList = await props.getContainerList();
      setContainerList(theList);
    }
    loadContainers();
    containerListIntervalId = setInterval(async () => {
      await loadContainers();
    }, 5000);
    return () => clearInterval(containerListIntervalId);
  }, []);

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container direction={"row"} spacing={1}>
            <Grid item>
              <AccountTreeIcon />
            </Grid>
            <Grid>
              <Typography color="secondary" variant="h5">
                Workloads
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ display: "flex", justifyContent: "flex-end" }}
          xs={4}
        >
          <Button
            color="primary"
            endIcon={<AddIcon />}
            onClick={props.handleCreateContainerOpen}
            style={{ minWidth: 250 }}
          >
            Create Container
          </Button>
          <Button
            color="primary"
            endIcon={<AddIcon />}
            onClick={props.handleClickOpen}
            style={{ minWidth: 250 }}
          >
            Create Virtual Machine
          </Button>
          <Button
            color="secondary"
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              window.open(
                "https://intersight.com/an/virtualization/virtualization/datacenter/",
                "_blank"
              )
            }
            style={{ minWidth: 250 }}
          >
            View in Intersight
          </Button>
          <Button
            color="secondary"
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              window.open(
                "https://172.24.4.19/dashboard/#!/login",
                "_blank"
              )
            }
            style={{ minWidth: 250 }}
          >
            View in Container Platform
          </Button>
        </Grid>
        <Grid item xs={8} style={{ paddingTop: 20 }}>
          <Typography color="secondary" variant="h6">
            VM's
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <VmTable vmList={vmList} />
        </Grid>
      </Grid>
      <Dialog
        open={!!props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <CreateVirtualMachine
            onCancel={props.handleClose}
            onCreate={props.handleChange}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!props.createContainerOpen}
        onClose={props.handleCreateContainerClose}
        aria-labelledby="create-container-dialog"
      >
        <DialogContent>
          <CreateContainer
            onCancel={props.handleCreateContainerClose}
            onCreate={props.handleCreateContainerChange}
          />
        </DialogContent>
      </Dialog>
      <Grid item xs={8} style={{ paddingTop: 20 }}>
        <Typography color="secondary" variant="h6">
          Containers
        </Typography>
      </Grid>
      <Grid container justify={"flex-start"}>
        <ContainerTable containerList={containerList} />
      </Grid>
    </Box>
  );
};

export default Virtualization;
